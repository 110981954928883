import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Button from '../components/Button';
import Video from '../components/Video';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Quicklinks from '../components/Quicklinks';

const CareerProfile = ({ data, pageContext: { orderLookup, relatedMap } }) => {
  const profile = data.allCareerProfilesYaml.edges[0].node;
  const sortIndex = parseInt(orderLookup[profile.parent.name]);
  const maxCareerIndex = parseInt(orderLookup['max']);

  return (
    <Layout
      title={`${profile.title} | Career Profiles`}
      className="career-profile"
    >
      <Section>
        <Container>
          <Row>
            <Column>
              <p>
                <Link to="/career-keys">&lsaquo; View all career profiles</Link>
              </p>
              <h2>{profile.title}</h2>
              <div className="career-profile__video-wrapper">
                {sortIndex > 1 && (
                  <Link
                    className="career-profile__switch-button career-profile__prev-button"
                    to={`/career-profiles/${orderLookup[sortIndex - 1]}`}
                  >
                    <Icon name="arrowleft" className="callout__icon" />
                  </Link>
                )}
                <Video src={profile.videoSrc} poster={profile.videoPoster} />
                {sortIndex < maxCareerIndex && (
                  <Link
                    className="career-profile__switch-button career-profile__next-button"
                    to={`/career-profiles/${orderLookup[sortIndex + 1]}`}
                  >
                    <Icon name="arrowright" className="callout__icon" />
                  </Link>
                )}
              </div>
            </Column>
          </Row>
          <Row>
            <Column size={8}>
              <h3>{profile.introHeader}</h3>
              <p dangerouslySetInnerHTML={{ __html: profile.introParagraph }} />
              <Quicklinks
                title={null}
                smoothScroll={true}
                links={[
                  {
                    target: '#about',
                    label: 'About',
                  },
                  {
                    target: '#activate',
                    label: 'Activate Student Interest',
                  },
                  {
                    target: '#additional',
                    label: 'Additional Careers',
                  },
                ]}
              />
              <h4 id="about" className="mt-2">
                About
              </h4>
              <p dangerouslySetInnerHTML={{ __html: profile.about }} />
              <p>
                {profile.aboutPDF && (
                  <Button to={profile.aboutPDF}>{profile.title}</Button>
                )}
              </p>
              <h4 className="mt-3">Evaluate Your Interest</h4>
              <p>
                Discover how this career aligns with your students' skills and
                personality.
              </p>
              <ul>
                {profile.evaluateChecklist &&
                  profile.evaluateChecklist.map((item) => {
                    return <li key={item}>{item}</li>;
                  })}
              </ul>
              <h4 id="activate" className="mt-3">
                Activate Student Interest
              </h4>
              {profile.activateIntro && <p>{profile.activateIntro}</p>}

              <ul>
                {profile.activateChecklist.map((item) => {
                  return <li key={item}>{item}</li>;
                })}
              </ul>
              <p className="mt-1">
                {profile.studentActivationPDF && (
                  <Button to={profile.studentActivationPDF}>
                    Student Activation (PDF)
                  </Button>
                )}
              </p>
            </Column>
            <Column size={4}>
              <Image filename={profile.quoteImage} />
              <div className="profile-quote mt-2">
                <p
                  className="profile-quote__quote"
                  dangerouslySetInnerHTML={{ __html: profile.quote }}
                />
                <p
                  className="profile-quote__attribution"
                  dangerouslySetInnerHTML={{ __html: profile.quoteAttribution }}
                />
              </div>
            </Column>
          </Row>
          <Row className="mt-3">
            <Column>
              <h3 id="additional">Additional Careers to explore</h3>
              <p>
                Go further into the world of cybercrime fighting by exploring
                additional STEM careers that are a great fit for students
                excited about tech and computing. Learn about the tools other
                cyber heroes use to problem-solve and defend our data in the
                digital world.
              </p>
            </Column>
          </Row>
          <Row>
            <Column size={6}>
              {profile.related[0] && (
                <>
                  <Image
                    filename={relatedMap[profile.related[0]].relatedImage}
                  />
                  <h4 className="mt-1">
                    {relatedMap[profile.related[0]].title}
                  </h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: relatedMap[profile.related[0]].shortDescription,
                    }}
                  />
                  <Button to={`/career-profiles/${profile.related[0]}`}>
                    Learn More
                  </Button>
                </>
              )}
            </Column>
            <Column size={6}>
              {profile.related[1] && (
                <>
                  <Image
                    filename={relatedMap[profile.related[1]].relatedImage}
                  />
                  <h4 className="mt-1">
                    {relatedMap[profile.related[1]].title}
                  </h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: relatedMap[profile.related[1]].shortDescription,
                    }}
                  />
                  <Button to={`/career-profiles/${profile.related[1]}`}>
                    Learn More
                  </Button>
                </>
              )}
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default CareerProfile;

export const query = graphql`
  query CareerTemplateQuery($id: String!) {
    allCareerProfilesYaml(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          sortIndex
          about
          aboutPDF
          additionalCareersParagraph
          activateIntro
          activateChecklist
          introHeader
          introParagraph
          related
          relatedImage
          studentActivationPDF
          videoPoster
          videoSrc
          quoteImage
          quote
          quoteAttribution
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  }
`;
